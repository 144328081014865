<template>
  <div class="footer">
    <div class="header">
      <div class="top w1150">
        <ul class="clearfix">
          <li>
            <a class="logo" href="javascript:;">北京世邦和广告传媒</a>
          </li>
          <li v-for="(item,index) of navList" :key="index">
            <a :class="{'active':active == index}" href="javascript:;" @click="navgate(item.path)">{{item.name}}</a>
          </li>
          <li>
            <a href="javascript:;" @click="bossManage">管理后台</a>
          </li>
        </ul>
        <div class="photo">
          <div class="clearfix" style="cursor: pointer;" @click="hiden">
            <img v-if="pic" :src="pic">
            <img v-else src="~@/assets/img/default_avatar.jpg"/>
            <span>{{username}}</span>
          </div>
          <div class="navbar" v-if="show">
            <a href="javascript:;" @click="changepswd">修改密码</a>
            <a href="javascript:;" @click="loginout">退出登录</a>
          </div>
        </div>
      </div>
    </div>
    <update-pwd ref="updatepwd" />
  </div>
</template>

<script>
import UpdatePwd from '@/views/UpdatePwd'
import {bossUrl} from '../utils/api'
export default {
  components:{
    UpdatePwd
  },
  data: function() {
    return {
      active: 0,
      show: false,
      navList: [
        {'name':'首页','path':'/'},
        {'name':'网站公告','path':'notice'},
        {'name':'值班人员','path':'operator'},
        {'name':'工作日志','path':'workslogs'}
      ]
    };
  },
  watch: {
    // 如果路由有变化，会再次执行该方法
    $route: "changeActive",
  },
  computed: {
    pic: {
      get () {
          return localStorage.getItem('pic')
      }
    },
    username: {
      get () {
          return localStorage.getItem('username')
      }
    }
  },
  methods: {
     changeActive() {
      if(typeof this.$route.meta.tabIndex !== 'undefined') {
        this.active = this.$route.meta.tabIndex
      }
    },
    navgate(path) {
      this.$router.replace({ path: path });
    },

    hiden(){
      this.show = !this.show
    },
    changepswd(){
      this.show = false
      this.$refs.updatepwd.handleDilog()
      // this.$router.replace({ path: 'updatepwd' });
    },
    loginout(){
      this.show = false
      localStorage.removeItem('dqtoken')
      localStorage.removeItem('pic')
      localStorage.removeItem('username')
      window.location.href = '/login'
    },
    bossManage () {
      window.open(bossUrl, '_blank')
    }

  },
};
</script>

<style lang="scss">
  .header{
    width: 100%;
    height: 64px;
    background: inherit;
    background-color: rgba(0, 21, 41, 1);
    border: none;
    border-radius: 0px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    .top{
      height: 64px;
      background-color: rgba(0, 21, 41, 1);
      .logo{
        font-size: 20px;
      }
      ul{
        float: left;
        li{
          float: left;
          a{
            display: block;
            height: 64px;
            line-height: 64px;
            text-align: center;
            background-color: rgba(0, 21, 41, 1);
            padding: 0 30px;
            color: #ddd;
          }
          a.active{
            color: #fff;
            background-color: rgba(0, 40, 77, 1);;
          }
        }
      }
      .photo{
        position: relative;
        float: right;
        margin-top: 12px;
        .navbar{
          position: absolute;
          top: 52px;
          left: 0;
          width: 100px;
          padding: 10px;
          background: #fff;
          border: 1px solid #eee;
          z-index: 5;
          a{
            display: block;
            font-style: 14px;
            color: #409EFF;
            line-height: 50px;
            text-align: center;
          }
        }
        img{
          float: left;
          width: 40px;
          height: 40px;
          border-radius: 99px;
          background: #eee;
        }
        span{
          float: left;
          font-size: 16px;
          color: #ddd;
          margin-left: 10px;
          margin-top: 8px;
        }
      }
    }
  }








</style>
