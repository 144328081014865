<template>
  <div class="container">
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <div class="login">
        <h2>修改密码</h2>
        <el-form status-icon ref="ruleForm" label-width="70px" @keyup.enter.native="submitForm()" class="demo-ruleForm">
          <el-form-item label="原密码">
            <el-input type="password" v-model="params.olgPwd" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="新密码">
            <el-input type="password" v-model="params.newPwd" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="确认密码">
            <el-input type="password" v-model="params.confirmPwd" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item style="margin-top:20px">
          </el-form-item>
          <!-- <div style="text-align: center; width: 100%;">
            <el-button type="primary" @click="submitForm()">确认修改</el-button>
            <el-button type="info" @click="back()">取消</el-button>
          </div> -->
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm()">确 定</el-button>
      </span>
    </el-dialog>


  </div>
</template>

<script>
import { updatePwd } from "../utils/api"
import { Toast } from "vant"
export default {
  name: "Login",
  data: function() {
    return {
      dialogVisible: false,
      params: {
        olgPwd: '',
        newPwd: '',
        confirmPwd: '',
      },
    }
  },
  computed:{

  },
  created() {

  },
  mounted() {

  },
  methods: {
    handleDilog(done) {
        this.reset()
        this.dialogVisible = true
    },

    async submitForm() {
      if(!this.params.olgPwd) {
        Toast('原密码不能为空')
        return;
      }
      if(!this.params.newPwd) {
          Toast('新密码不能为空')
          return;
      }
      if(!this.params.confirmPwd) {
          Toast('确认密码不能为空')
          return;
      }
      if(this.params.newPwd !== this.params.confirmPwd){
          Toast('两次密码输入不一致')
          return;
      }
      const json = await updatePwd({
        query: this.params,
        method: "post"
      });
      if (json.code == "200") {
        Toast('操作成功')
        this.dialogVisible = false
          // location.href = '/'
      }else{
          Toast(json.msg)
      }

    },
    handleClose(done){
        this.dialogVisible = false
    },
    reset(){
      this.params = {
        'olgPwd': '',
        'newPwd': '',
        'confirmPwd': ''
      }
    }
  },
};
</script>
<style scoped lang="scss">
  .container{
      .login{
        // width: 400px;
        // height: 360px;
        // padding: 20px;
        background: #fff;
        box-sizing: border-box;
        h2{
          text-align: center;
          padding: 0 0 15px 0;
        }
        .uuid{
          float: left;
          width: 130px;
          height: 40px;
          margin-left: 20px;
          cursor: pointer;
        }
    }
  }

</style>
