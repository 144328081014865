import wxRequest, {wxRequestFile, wxRequestBase64} from './wxRequest';

export const url = 'https://cms.shibanghe.cn/apis'

export const bossUrl = 'https://boss.shibanghe.cn'

export const login = (params) => wxRequest(params, `${url}/login`)

export const homelist = (params) => wxRequest(params, `${url}/api/home/page`)

// 工作日志
export const jobLog = (params) => wxRequest(params, `${url}/api/jobLog/info`)

// 网站公告list
export const notice = (params) => wxRequest(params, `${url}/api/notice/page`)

// 字典表
export const dict = (params) => wxRequest(params, `${url}/api/home/dictList`)

//值班人员
export const duty = (params) => wxRequest(params, `${url}/api/duty/info`)

//公告详情
export const noticedetail = (params) => wxRequest(params, `${url}/api/notice/info`)

// 信息详情接口
export const newsdetail = (params) => wxRequest(params, `${url}/api/home/info`)

//修改密码
export const updatePwd = (params) => wxRequest(params, `${url}/api/user/updatePwd`)

// 最新公告
export const latestNotice = (params) => wxRequest(params, `${url}/api/notice/getLatest`)





