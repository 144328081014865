import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'Home',
		component: Home,
		meta: {
			tabIndex: 0
		}
	},
	
	{
		path: '/login',
		name: 'Login',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import( /* webpackChunkName: "about" */ '../views/Login.vue'),
			meta: {
				hideBar: true
			}
	},
	{
		path: '/notice',
		name: 'Notice',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import( /* webpackChunkName: "about" */ '../views/Notice.vue'),
			meta: {
				tabIndex: 1
			}
	},
	{
		path: '/operator',
		name: 'Operator',
		component: () =>
			import( /* webpackChunkName: "about" */ '../views/Operator.vue'),
			meta: {
				tabIndex: 2
			}
	},
	{
		path: '/workslogs',
		name: 'WorksLogs',
		component: () =>
			import( /* webpackChunkName: "about" */ '../views/WorksLogs.vue'),
			meta: {
				tabIndex: 3
			}
	},
	{
		path: '/noticedetails',
		name: 'NoticeDetails',
		component: () =>
			import( /* webpackChunkName: "about" */ '../views/NoticeDetails.vue'),
			meta: {
				tabIndex: 1
			}
	},
	{
		path: '/newsdetails',
		name: 'NewsDetails',
		component: () =>
			import( /* webpackChunkName: "about" */ '../views/NewsDetails.vue'),
			meta: {
				tabIndex: 0
			}
	},
	{
		path: '/updatepwd',
		name: 'UpdatePwd',
		component: () =>
			import( /* webpackChunkName: "about" */ '../views/UpdatePwd.vue'),
			meta: {
				hideBar: true
			}
	},
	
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})


router.beforeEach((to, from, next) => {
  if (window._hmt) {
    if (to.path) {
      window._hmt.push(['_trackPageview', '/#' + to.fullPath])
    }
  }
  next()
})

export default router


