@include query-form-style(); // 顶部查询列表样式
<style scoped lang="scss">
form{
  padding-top: 30px;
}
.el-date-editor.el-input{
  width: auto !important;
}
  .newList{
    padding: 0 15px 15px;
    background: #fff;
    margin: 10px auto;
    h4{
      padding: 10px 0;
      font-size: 18px;
      color: #000;
      font-weight: 700;
      border-bottom: 1px solid #eee;
    }
  }
  .el-form-item {
    margin-bottom: 0px;
  }
  .timeselect{
    float: left;
    margin-top: 4px;
    a{
      float: left;
      display: block;
      height: 32px;
      line-height: 32px;
      text-align: center;
      padding: 0 20px;
      background: #fff;
      border: 1px solid #eee;
      margin-right: 5px;
      border-radius: 4px;
      color: #333;
      font-size: 14px;
    }
    a.select{
      border: 1px solid #1890FF;
      color: #1890FF;
    }
  }


  .newContent{
    margin: 15px auto;
    padding: 15px;
    background: #fff;
    .newitem{
      position: relative;
      min-height: 200px;
      padding: 15px 0;
      border-bottom: 1px solid #eee;
      h2{
        font-size: 20px;
        color: #333;
        font-weight: 700;
        padding-bottom: 10px;
      }
      .title {
        cursor: pointer;
      }
      .text{
        text-indent: 28px;
        font-size: 14px;
        color: #666;
        line-height: 150%;
        margin: 15px 0;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      .bottom{
        position: absolute;
        bottom: 5px;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .from{
          img{
            display: inline-block;
            width: 15px;
            height: 15px;
            background: #eee;
            border-radius: 99px;
          }
          span{
            font-size: 14px;
            color: #666;
            margin-left: 8px;
          }
          span.color{
            color: #1890FF;
          }
          .time{
            float: left;
            margin-left: 100px;
            color: #999;
          }
        }
        .btn{
          a{
            font-size: 14px;
            color: #999;
            display: inline-block;
            padding: 0 10px;
            border-left: 1px solid #eee;
          }
          a:first-child{
            border-left: 0;
          }
        }

      }
    }
  }

</style>
<template>
  <div class="container">
    <!-- 新闻列表 -->
    <div class="w1150" style="width:1180px;margin-top:15px;cursor: pointer;">
      <van-notice-bar
        left-icon="volume-o"
        :text="notice.title"
        @click="noticeShowHandle(notice.id)"
      />
    </div>
    <div class="newList w1150">
      <h4>舆情信息列表</h4>
      <el-form ref="form" class="query-form clearfix" label-width="100px">
        <el-form-item label="全文" style=" width:450px; margin-top: 10px;border-bottom: 1px dotted #eee;padding-bottom: 10px;">
          <el-input v-model="queryform.title" placeholder="请输入全文关键字" style="width: 240px"></el-input>
        </el-form-item>
        <el-form-item label="发布时间" style="margin-top: 10px;border-bottom: 1px dotted #eee;padding-bottom: 10px;">
          <div class="timeselect clearfix">
            <a v-for="(item,index) of timeoption" :key="index" href="javascript:;" :class="{'select': selectindex == index}" @click="hanldeItem(item.value,index)">{{ item.name }}</a>
          </div>
          <div class="clearfix">
            <span style="float:left;margin-left:50px;margin-right:10px;color:#606266;">自定义日期</span>
            <el-date-picker
                    v-model="dateRange"
                    type="datetimerange"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    @change="getDateVal">
            </el-date-picker>
          </div>
        </el-form-item>
        <el-form-item label="客户" style="margin-top: 10px;border-bottom: 1px dotted #eee;padding-bottom: 10px;">
          <el-checkbox-group v-model="queryform.customer" @change="handleCheckedCustomerChange">
            <el-checkbox v-for="(item,index) of dict.customerList" :key="index" :label="item.value" name="type">{{item.label}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="文章类型" style="margin-top: 10px;border-bottom: 1px dotted #eee;padding-bottom: 10px;">
          <el-checkbox-group v-model="queryform.sourceType">
            <el-checkbox v-for="(item,index) of dict.sourceTypeList" :key="index" :label="item.value" :value="item.value" name="type">{{item.label}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="类型" style="margin-top: 10px;border-bottom: 1px dotted #eee;padding-bottom: 10px;">
          <el-checkbox-group v-model="queryform.type">
            <el-checkbox v-for="(item,index) of dict.typeList" :key="index" :label="item.value" :value="item.value" name="type">{{item.label}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="专项分类" style="margin-top: 10px;border-bottom: 1px dotted #eee;padding-bottom: 10px;">
          <el-checkbox-group v-model="queryform.special">
            <el-checkbox v-for="(item,index) of dict.specialList" :key="index" :label="item.value" :value="item.value" name="type">{{item.label}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="属性" style="margin-top: 10px;border-bottom: 1px dotted #eee;padding-bottom: 10px;">
          <el-checkbox-group v-model="queryform.attribute">
            <el-checkbox v-for="(item,index) of dict.attributeList" :key="index" :label="item.value" :value="item.value" name="type">{{item.label}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item style="margin-top: 10px;">
          <el-button type="primary" @click="query">搜索</el-button>
          <el-button type="primary" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="newContent w1150">
      <div class="newitem" v-for="(item,index) of homeList" :key="index">
        <h2 @click="handleDeatil(item.infoId)" class="title">{{ item.title }}</h2>
        <div class="screen">
          <el-button type="primary" size="mini" v-if="item.customerLabel">{{ item.customerLabel }}</el-button>
          <el-button type="success" size="mini" v-if="item.attributeLabel">{{ item.attributeLabel }}</el-button>
          <el-button type="warning" size="mini" v-if="item.sourceTypeLabel">{{item.sourceTypeLabel}}</el-button>
          <el-button type="danger" size="mini" v-if="item.specialLabel">{{item.specialLabel}}</el-button>
          <el-button size="mini" v-if="item.typeLabel">{{item.typeLabel}}</el-button>
        </div>
        <div class="text">
          {{ item.abstractContext }}
        </div>
        <div class="bottom">
          <div class="from">
            <div style="float: left;">
              <span class="color">{{ item.author }}</span>
              <span>发布在</span>
              <span class="color">{{ item.website }}</span>
            </div>
            <div class="time">{{ item.sourceTime }}</div>
          </div>
          <div class="btn">
            <a href="javascript:;" @click="readSrcHandle(item.url)">阅读原文</a>
            <a href="javascript:;" v-if="item.isFollow === 1">已加入跟进</a>
            <a href="javascript:;" v-else>未加入跟进</a>
            <a href="javascript:;" v-if="item.isPost === 1">已加入报告</a>
            <a href="javascript:;" v-else>未加入报告</a>
          </div>
        </div>
      </div>

      <!-- 分页 -->
      <el-pagination
              :current-page.sync="queryform.current"
              :page-sizes="[30, 50, 100]"
              :page-size.sync="queryform.size"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              @current-change="currentChangeHandle"
              @size-change="sizeChangeHandle"
      />

    </div>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import { homelist,dict, latestNotice } from "../utils/api"
import { Toast } from "vant"
export default {
  name: "Home",
  data: function() {
    return {
      queryform: {
        current: 1,
        size: 30,
        startDate: '', // 开始时间
        endDate: '', // 结束时间
        title: '', // 标题
        customer: [], // 客户
        sourceType: [], // 文章类型
        type: [],  // 类型
        special: [],  // 专项分类
        attribute: [],  // 属性
      },
      customerIsIndeterminate: false,
      customerCheckAll: true,
      dateRange: [],
      startDate: '',
      endDate: '',
      dict:{
        customerList: [], // 客户list
        sourceTypeList: [], // 文章类型list
        typeList: [],
        specialList: [],
        attributeList: []
      },
      notice: {},
      total: 0,
      selectindex: null,
      timeoption: [
        {"name": "今天", "value": "0"},
        {"name": "昨天", "value": "-1"},
        {"name": "近三天", "value": "-3"},
        {"name": "近七天", "value": "-7"}
      ],
      homeList: []
    }
  },
  computed:{

  },
  created() {

  },
  mounted() {
    this.getDict()
    this.query()
    this.getLatest()
  },
  methods: {
    // 详情
    handleDeatil(infoId){
      this.$router.push({ path: "newsdetails", query: { id: infoId} })
    },
    // 打开原文
    readSrcHandle (url) {
        window.open(url, '_blank')
    },
    async getList(params) {
      const json = await homelist({
        query: params,
        method: "get"
      });
      if (json.code == "200") {
        this.homeList = json.data.records
        this.total = json.data.total
        this.queryform.current = json.data.current
      }
    },
    customerHandleCheckAllChange (val) {
        this.queryform.customer = val ? this.dict.customerList.map(item=>{return item.value}) : []
        this.customerIsIndeterminate = false
    },
    handleCheckedCustomerChange (value) {
        let checkedCount = value.length
        this.customerCheckAll = checkedCount === this.dict.customerList.length
        this.customerIsIndeterminate = checkedCount > 0 && checkedCount < this.dict.customerList.length
    },
    // 打开公告详情
    noticeShowHandle (id) {
        this.$router.push({ path: "noticedetails", query: { id:  id} })
    },
    // 获取最新公告
    async getLatestNotice () {
        const json = await latestNotice({
            query: {},
            method: "get"
        });
        if (json.code === 200) {
            this.notice = json.data
        }
    },
    hanldeItem(value,index){
      if(this.startDate || this.endDate){
        this.startDate = this.endDate = ''
      }
      if (this.dateRange) {
          this.dateRange = []
      }
      this.selectindex = index
      switch(value){
						// 今天
					 case '0':
                          this.queryform.startDate = dayjs(new Date()).format('YYYY-MM-DD') + ' 00:00:00'
                          this.queryform.endDate = dayjs(new Date()).format('YYYY-MM-DD') + ' 23:59:59'
                          break;
					   // 昨天
					 case '-1':
                          this.queryform.startDate = dayjs(new Date().getTime()-1*24*60*60*1000).format('YYYY-MM-DD') + ' 00:00:00'
                          this.queryform.endDate = dayjs(new Date().getTime()-1*24*60*60*1000).format('YYYY-MM-DD') + ' 23:59:59'
                          break;
					 // 近三天
					 case '-3':
                          this.queryform.startDate = dayjs(new Date().getTime()-2*24*60*60*1000).format('YYYY-MM-DD') + ' 00:00:00'
                          this.queryform.endDate = dayjs(new Date()).format('YYYY-MM-DD') + ' 23:59:59'
                          break;
					 // 近七天
					 case '-7':
                          this.queryform.startDate = dayjs(new Date().getTime()-6*24*60*60*1000).format('YYYY-MM-DD') + ' 00:00:00'
                          this.queryform.endDate = dayjs(new Date()).format('YYYY-MM-DD') + ' 23:59:59'
					       break;

					 default:
					   break;
			    }
    },
    query(){
      let params = {
        'current': this.queryform.current,
        'size': this.queryform.size,
        'startDate': this.queryform.startDate || this.startDate, // 开始时间
        'endDate': this.queryform.endDate || this.endDate, // 结束时间
        'title': this.queryform.title, // 标题
        'customer': this.queryform.customer.join(','),
        'sourceType': this.queryform.sourceType.join(','),
        'type': this.queryform.type.join(','),
        'special': this.queryform.special.join(','),
        'attribute': this.queryform.attribute.join(',')
      }
      this.getList(params)
    },
    getLatest () {
        this.getLatestNotice()
    },
    // 获取日期
    getDateVal (val) {
        this.selectindex = null
        this.queryform.startDate = val[0]
        this.queryform.endDate = val[1]
    },
    // 每页数
    sizeChangeHandle (val) {
        this.queryform.size = val
        this.queryform.current = 1
        this.query()
    },
    // 当前页
    currentChangeHandle (val) {
        this.queryform.current = val
        this.query()
    },
    async getDict(){
      const json = await dict({
        query: {'type':'CUSTOMER'},
        method: "get"
      });
      if (json.code == "200") {
        this.dict.customerList = json.data
      }

      const json1 = await dict({
        query: {'type':'MEDIA_TYPE'},
        method: "get"
      });
      if (json1.code == "200") {
        this.dict.sourceTypeList = json1.data
      }

      // 类型
      const json2 = await dict({
        query: {'type':'NEWS_TYPE'},
        method: "get"
      });
      if (json2.code == "200") {
        this.dict.typeList = json2.data
      }

      // 专项分类
      const json3 = await dict({
        query: {'type':'SPECIAL'},
        method: "get"
      });
      if (json3.code == "200") {
        this.dict.specialList = json3.data
      }

      // 属性
      const json4 = await dict({
        query: {'type':'NEWS_ATTRIBUTE'},
        method: "get"
      });
      if (json4.code == "200") {
        this.dict.attributeList = json4.data
      }

    },

    reset () {
        this.selectindex = 0
        this.queryform.title = ''
        this.queryform.customer = []
        this.queryform.sourceType = []
        this.queryform.type = []
        this.queryform.attribute = []
        this.queryform.special = []
        this.queryform.startDate = ''
        this.queryform.endDate = ''
        this.selectindex = -1
        this.dateRange = []
    }
  },
};
</script>
