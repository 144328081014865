import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import store from './store'
import Footer from './components/Footer.vue'
import dayjs from "dayjs"
import VueClipboard from 'vue-clipboard2'
import { NoticeBar } from 'vant';

Vue.use(NoticeBar);
Vue.use(ElementUI);
Vue.use(VueClipboard)

Vue.prototype.dayjs = dayjs
Vue.component('footer-view', Footer)
Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')