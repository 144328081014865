import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    formInfo: {}, // 员工信息
  },
  mutations: {
    SAVE_FORM_INFO: (state, formInfo) => {
      state.formInfo = formInfo
    }
  },
  actions: {
    getFormInfo({ commit, state }, formInfo) {
　　　 commit('SAVE_FORM_INFO',formInfo)
    }
  },
  modules: {
  }
})
