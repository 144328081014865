<template>
  <div id="app">
    <footer-view v-show="!$route.meta.hideBar"></footer-view>
    <router-view />
  </div>
</template>
<script>
export default {
  name: "Home",
  data: function() {
    return {};
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss">
body,
html {
  background: #f2f2f2;
  color: #333;
  height: 100%;
  width: 100%;
}
.w1150{
  width: 1150px;
  margin: 0 auto;
}
.newdetail{
  min-height: 500px;
}
.el-pagination{
  margin-top: 15px;
}
.clearfix:after{visibility:hidden;display:block;font-size:0;content:" ";clear:both;height:0;}
@mixin query-form-style {
  .query-form {
    // 查询表单所有下拉框与输入框设定固定大小
    .el-col {
      padding: 0 10px;
    }
    .el-form-item {
      width: 100%;
      display: flex;
      /deep/.el-form-item__label {
        font-weight: normal;
        min-width: 70px;
        margin-right: 0;
      }
      /deep/.el-form-item__content {
        flex: 1;
      }
      // 表单内文本框、下拉框、范围选择器日期固定
      .el-select,
      .el-input {
        width: 100%;
      }
      .el-range-editor {
        width: 100%;
      }
      .el-radio-group {
        display: flex;
        height: 100%;
        align-items: center;
        .el-radio {
          margin-right: 24px;
        }
        /deep/.el-radio__label {
          font-size: 12px;
          padding-left: 6px;
        }
      }
      // 查询、重置按钮样式
      .button-submit {
        min-width: 78px;
        margin-left: 20px;
      }
    }
  }
}
// .van-nav-bar .van-nav-bar__left, .van-nav-bar .van-nav-bar__title, .van-nav-bar .van-nav-bar__text{color: #fff !important;}
// .van-icon-arrow-left::before{color: #fff !important;}

// .van-checkbox__icon--checked .van-icon {
//       background-color: #ffca02;
//     border-color: #ffca02;
// }
.van-radio__icon--checked .van-icon {
    color: #fff;
   background-color: #ffca02;
    border-color: #ffca02;
}
.van-nav-bar {
  // background-color: #ffca02;
  background-color: #fff;
  height: 45px;
  overflow: hidden;
  .van-nav-bar__left,
  .van-nav-bar__title,
  .van-nav-bar__text {
    color: #333333;
    /* font-weight: bold; */
    font-size: 15px;
  }
  .van-nav-bar__left {
    left: 10px;
  }
}
.van-tabbar-item:not(.van-tabbar-item--active) {
    .active {
        display: none;
    }
}
.van-pull-refresh {
    background: #f2f2f2;
}
.van-tabbar-item--active {
  .normal {
	display:none;
  }

  img {
    color: #fff;
  }
}
.van-tabbar-item--active {
  color: rgb(255, 202, 2);
}
.van-tabbar-item__icon img {
  display: block;
  height: 21px;
}
.van-icon-arrow-left::before {
  color: #333;
}
img {
  max-width: 100%;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}
.van-tabs__line {
  background-color: #ffca02;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#nav {
  a {
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
