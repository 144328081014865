import axios from 'axios';
import { Toast } from 'vant';

const wxRequest = async (params = {}, url) => {
    if(!params.nospin){
        Toast.loading({
            message: '加载中...',
            forbidClick: true
        });
    }
    const token = localStorage.getItem('dqtoken')
    let data = params.query || {}

    return new Promise((resolve, reject) => {
        axios({
            url: url,
            method: params.method.toUpperCase == 'FORM' ? 'POST' : params.method || 'GET',
            data: data,
            params: data,
            headers: {
                'Content-Type': params.method == 'FORM' ? 'multipart/form-data' : 'application/json;charset=UTF-8;',
                'token': `${token}`,
            }
        }).then(function (data) {

            if (data.data.code.toString() == '401') {
                localStorage.removeItem('dqtoken');
                Toast('登录信息失效，请重新登录');
                setTimeout(() => {
                    window.location.href = '/login'
                    // window.location.href = '/login?timestamp='+new Date().getTime()+Math.random()
                }, 1000)
            }
            if (data.data.code != '401' && data.data.code != '200') {
                // wx.showToast({
                //     title: data.data.errorMessage ? data.data.errorMessage : '',
                //     icon: 'none',
                //     duration: 2000
                // })
            }

            if (data && data.data) {
                console.log(`fetchData url: ${url}`, data.data);
            }
            Toast.clear();
            resolve(data.data)
        })
            .catch(function (error) {
                // handle error
                Toast.clear();
                console.log(error);
            })
    })
}

export const wxRequestBase64 = async (params = {}, url) => {
    if(!params.nospin){
        Toast.loading({
            message: '加载中...',
            forbidClick: true
        });
    }
    // const token = localStorage.getItem('dqtoken')
    let data = params.query || {}

    return new Promise((resolve, reject) => {
        axios({
            url: url,
            method: params.method.toUpperCase == 'FORM' ? 'POST' : params.method || 'GET',
            data: data,
            // params: data,
            headers: {
                'Content-Type': params.method == 'FORM' ? 'multipart/form-data' : 'application/json;charset=UTF-8;',
                // 'token': `${token}`,
            }
        }).then(function (data) {
            // handle success

            // setTimeout(function () {
            // wx.hideLoading()
            // }, 500)

            // if (data.data.code === 401) {
                // wx.showToast({
                //     title: '请到“我的”页面点击头像授权登陆',
                //     icon: 'none',
                //     duration: 2000
                // })
                // wx.stopPullDownRefresh();
                //  wx.clearStorage();
                
                // localStorage.removeItem('dqtoken');
                // Toast('登录信息失效，请重新登录');
                // setTimeout(() => {
                //     window.location.href = '/login'
                // }, 1000)
                
                // wx.showModal({
                //   title: '提示',
                //   content: '由于您长时间未操作，请重新授权登录！',
                //   showCancel: false,
                //   success (res) {
                //     wx.stopPullDownRefresh();
                //     wx.clearStorage();
                //     wx.reLaunch({
                //       url: '/pages/loginAuthorization'
                //     })
                //   },
                //   fail () {
                //     wx.stopPullDownRefresh();
                //     wx.clearStorage();
                //     wx.reLaunch({
                //       url: '/pages/loginAuthorization'
                //     })
                //   }
                // })
            // }
            if (data.data.code != '401' && data.data.code != '200') {
                // wx.showToast({
                //     title: data.data.errorMessage ? data.data.errorMessage : '',
                //     icon: 'none',
                //     duration: 2000
                // })
            }

            if (data && data.data) {
                console.log(`fetchData url: ${url}`, data.data);
            }
            Toast.clear();
            resolve(data.data)
        })
            .catch(function (error) {
                // handle error
                Toast.clear();
                console.log(error);
            })
    })
}

export const wxRequestFile = async (params = {}, url) => {
    Toast.loading({
        message: '加载中...',
        forbidClick: true
    });
    // const token = localStorage.getItem('dqtoken')
    let data = params.query || {}

    const photoFormData = new FormData();
    // dict of all elements
    photoFormData.append("file", data);
    return new Promise((resolve, reject) => {
        axios({
            url: url,
            data: photoFormData,
            method: 'POST',
            headers: {
                'Content-Type': params.method == 'FORM' ? 'application/x-www-form-urlencoded' : 'application/x-www-form-urlencoded',
                // 'token': `${token}`,
            }
        }).then(function (data) {
            if (data && data.data) {
                console.log(`fetchData url: ${url}`, data.data);
            }
            Toast.clear();
            resolve(data.data)
        })
            .catch(function (error) {
                // handle error
                Toast.clear();
                console.log(error);
            })
    })
}


export default wxRequest;